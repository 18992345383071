import * as React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import {Helmet} from 'react-helmet';

export default function PrivacyPolicy() {
    return (
        <Container>
            <Helmet>
                <title>Polityka Prywatności | Ewieczyste.pl</title>
                <meta name="description"
                      content="Dowiedz się, jak Ewieczyste.pl chroni Twoją prywatność. Zapoznaj się z naszą polityką prywatności dotyczącą przetwarzania i ochrony danych osobowych."/>
            </Helmet>
            <Box sx={{my: 4}}>
                <Typography variant="h2" component="h1" gutterBottom>
                    Polityka Prywatności
                </Typography>

                <Typography variant="h6" component="h2" gutterBottom>
                    1. Informacje ogólne
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Niniejsza polityka prywatności określa zasady korzystania z naszej wyszukiwarki ksiąg
                    wieczystych.
                </Typography>
                <Divider/>

                <Typography variant="h6" component="h2" gutterBottom>
                    2. Brak przetwarzania danych osobowych
                </Typography>
                <Typography variant="body1" gutterBottom>
                    W ramach naszej wyszukiwarki ksiąg wieczystych nie przetwarzamy żadnych danych osobowych. Wszystkie
                    działania użytkownika na stronie są anonimowe i nie są rejestrowane.
                </Typography>
                <Divider/>

                <Typography variant="h6" component="h2" gutterBottom>
                    3. Pliki cookies
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Nasz serwis nie korzysta z plików cookies. Nie zbieramy, nie przechowujemy ani nie przetwarzamy
                    żadnych informacji za pomocą plików cookies.
                </Typography>
                <Divider/>

                <Typography variant="h6" component="h2" gutterBottom>
                    4. Zmiany w polityce prywatności
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Polityka prywatności może ulec zmianom, o czym Użytkownicy zostaną odpowiednio poinformowani. Dalsze
                    korzystanie z serwisu oznacza akceptację zmian.
                </Typography>
                <Divider/>

            </Box>
        </Container>
    );
}
