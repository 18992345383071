import React, {useEffect, useState} from 'react';
import {Helmet} from "react-helmet";
import Input from '@mui/material/Input';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import SearchIcon from '@mui/icons-material/Search';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import {useNavigate} from "react-router-dom"


import Results from "./Results";
import Footer from "./Footer";
import LandingDescription from "./LandingDescription";

async function FindKW({parcelId}) {
    const response = await fetch('https://api.ewieczyste.pl/prod/kwNumber?' +
        'evidence_id=' + parcelId, {
        method: 'GET',
        headers: {'APIkey': 'CMmyPILZvBZ589OI0Ax9J3hs'}
    });
    const KWResults = await response.json();
    return KWResults;
}

async function GetStripeUrl(kwId) {
    console.log(kwId);
    const response = await fetch('https://api.ewieczyste.pl/prod/getStripePaymentUrl?' +
        'kw_id=' + kwId, {
        method: 'GET',
        headers: {'APIkey': 'CMmyPILZvBZ589OI0Ax9J3hs'}
    });
    const result = await response.json();
    return result.url;
}

function getWindowDimensions() {
    const {innerWidth: width, innerHeight: height} = window;
    return {
        width,
        height
    };
}

function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}


function Landing() {
    const {height, width} = useWindowDimensions();
    const [parcelId, setParcelId] = useState(null);
    const [kwResults, setKwResults] = useState(null);
    const [isMobile, setIsMobile] = useState(false);


    const navigate = useNavigate()

    const displayKw = e => {
        let kw_id = e.target.value;
        const fetchStripeUrl = async () => {
            const stripeUrl = await GetStripeUrl(e.target.value);
            window.location.replace(stripeUrl);
        }
        fetchStripeUrl();
    }

    const handleParcelIdChange = (event) => {
        setParcelId(event.target.value);
    };

    const updateResults = () => {
        const fetchKWResults = async () => {
            const KWResults = await FindKW({parcelId});
            setKwResults(KWResults.results);
        }
        fetchKWResults();
    }

    useEffect(() => {
        document.title = "Wyszukiwarka ksiąg wieczystych - znajdź informacje o nieruchomościach";

        setIsMobile(width < 1000);
    }, [width < 1000]);
    return (
        <>
            <Helmet>
                <title>Wyszukiwarka Ksiąg Wieczystych - Znajdź Informacje o Nieruchomościach</title>
                <meta name="description"
                      content="Znajdź księgę wieczystą po numerze działki Nasza wyszukiwarka ksiąg wieczystych umożliwia odnalezienie pełnych informacji o dowolnej nieruchomości. Wpisz id działki i odkryj szczegóły dotyczące danej nieruchomości już teraz!"/>
                <meta name="keywords"
                      content="księgi wieczyste, wyszukiwarka, po numerze działki, numer księgi wieczystej, znajdź księgę wieczystą, nieruchomości, działki, numer działki, informacje o nieruchomości, wyszukiwarka, księga wieczysta online"/>
                <meta name="robots" content="index, follow"/>
                <meta property="og:title"
                      content="Wyszukiwarka Ksiąg Wieczystych - Znajdź Informacje o Nieruchomościach"/>
                <meta property="og:description"
                      content="Znajdź księgę wieczystą po numerze działki. Nasza wyszukiwarka ksiąg wieczystych umożliwia odnalezienie pełnych informacji o dowolnej nieruchomości. Wpisz id działki i odkryj szczegóły dotyczące danej nieruchomości już teraz!"/>
            </Helmet>
            <div>
                <div style={{
                    height: isMobile ? 650 : 500,
                    width: '100%',
                    backgroundImage: "url(" + "img/house_background.jpg" + ")",
                    backgroundSize: 'cover',
                    'background-position': 'center'
                }}>
                    <div style={{
                        'padding-left': isMobile ? 10 : ((width - 800) / 2),
                        'padding-top': 80,
                        'padding-right': isMobile ? 10 : 0
                    }}>
                        <div style={{
                            width: isMobile ? '100%' : 800,
                            height: isMobile ? 450 : 300,
                            backgroundColor: 'rgba(255,255,255,.97)',
                            'padding-left': isMobile ? 20 : 50,
                            'padding-right': isMobile ? 20 : 0,
                            'padding-top': 10
                        }}>

                            <h1 style={{
                                'font-family': 'lato', 'font-size': 28, color: '#3D3C09', fontWeight: 500,
                                'padding-left': isMobile ? 0 : 10
                            }}>
                                Wyszukaj numer księgi po numerze działki
                            </h1>
                            <div style={{'margin-top': 20, height: isMobile ? 120 : 65}}>
                                <TextField style={{width: isMobile ? '100%' : 540}} onChange={handleParcelIdChange}
                                           placeholder="np. 100804_2.0008.AR_1.22/3"/>
                                <Button style={{
                                    width: 130,
                                    height: 55,
                                    'margin-top': isMobile ? 10 : 0,
                                    float: isMobile ? 'right' : 'none',
                                    fontSize: 16,
                                    fontWeight: 500,
                                    'font-family': 'lato'
                                }}
                                        variant="contained" size="large" endIcon={<SearchIcon/>}
                                        onClick={updateResults}>
                                    Szukaj
                                </Button>
                            </div>
                            <div style={{'margin-top': 30, height: 200}}>
                                <div style={{float: 'left', width: '20%', 'padding-top': isMobile ? 40 : 0}}>
                                    <QuestionMarkIcon style={{width: 64, height: 64, color: '#656566'}}/>
                                </div>
                                <div style={{float: 'left', width: isMobile ? '80%' : 600}}>
                                    <span style={{
                                        'font-family': 'lato',
                                        'font-size': 16,
                                        color: '#3D3C09',
                                        fontWeight: 500
                                    }}>
                                        Wyszukaj numer księgi wieczystej po identyfikatorze działki. np. 302205_5.0011.351/33 (TERYT.obręb.działka). Bazę TERYT znajdziesz na www.stat.gov.pl lub odszukasz na mapie Geoportal.
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div style={{height: isMobile ? 2300 : 1500, 'padding-top': 10, backgroundColor: 'white'}}>

                    {kwResults &&
                        <div style={{
                            backgroundColor: 'white', width: '100%', 'padding-left': isMobile ? 0 : (width - 1000) / 2,
                            'padding-right': isMobile ? 0 : (width - 1000) / 2,
                            'padding-bottom': 20, 'font-family': 'lato'
                        }}>
                            <Results kwResults={kwResults} displayKw={displayKw} isMobile={isMobile}/>
                        </div>
                    }
                    {!kwResults && <LandingDescription/>}
                </div>
                <div>

                </div>
            </div>
            <Footer/>
        </>
    )
}

export default Landing;
