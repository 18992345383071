import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

function getWindowDimensions() {
    const {innerWidth: width, innerHeight: height} = window;
    return {
        width,
        height
    };
}

function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}

function Copyright() {
    return (
        <Typography variant="body2" color="text.secondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://ewieczyste.pl/">
                ewieczyste.pl
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

function Footer(params) {
    const {height, width} = useWindowDimensions();
    return (
        <>
            <footer>
                <Box component="footer" sx={{bgcolor: 'background.paper', py: 6}}>
                    <Container maxWidth="lg">
                        <Typography variant="h6" align="center" gutterBottom>
                            Wyszukiwarka ksiąg wieczystych online
                        </Typography>
                        <Typography
                            variant="subtitle1"
                            align="center"
                            color="text.secondary"
                            component="p"
                        >

                        </Typography>
                        <Copyright/>
                    </Container>
                </Box>
            </footer>
        </>
    );
}

export default Footer;
