import * as React from 'react';
import {Helmet} from "react-helmet";
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import GitHubIcon from '@mui/icons-material/GitHub';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import Header from './Header';
import MainFeaturedPost from './MainFeaturedPost';
import FeaturedPost from './FeaturedPost';
import Main from './Main';
import Sidebar from './Sidebar';
import Footer from './Footer';
import post1 from './posts/blog-post.1.md';
import post2 from './posts/blog-post.2.md';
import post3 from './posts/blog-post.3.md';
import {useParams} from 'react-router-dom';

let blogTitle = "Blog Wyszukiwarki Ksiąg Wieczystych - Najnowsze Aktualności i Poradniki";
let blogDescription = "Poznaj najnowsze aktualności, poradniki i informacje związane z działalnością naszej wyszukiwarki ksiąg wieczystych. Dowiedz się więcej o swoich prawach dotyczących nieruchomości i jak korzystać z naszych usług.";

const mainFeaturedPost = {
    title: 'Księgi Wieczyste: Wszystko, Co Musisz Wiedzieć',
    description:
        "Księgi wieczyste to publiczne rejestry nieruchomości, które zawierają szczegółowe informacje na temat praw związanych z daną nieruchomością.",
    image: 'https://source.unsplash.com/random',
    imageText: 'main image description',
    linkText: 'Continue reading…',
};

const featuredPosts = [
    {
        title: 'Poradnik Kupującego',
        date: '7 lipca',
        description:
            'Zakup nieruchomości to ważna decyzja życiowa, która wymaga odpowiedniego przygotowania i staranności.',
        image: 'https://source.unsplash.com/random',
        imageLabel: 'Image Text',
    },
    {
        title: '10 Ważnych Czynników',
        date: '7 lipca',
        description:
            'Sprawdź stan techniczny nieruchomości. Czy wymaga remontu czy modernizacji? ',
        image: 'https://source.unsplash.com/random',
        imageLabel: 'Image Text',
    },
];

const posts = [post1, post2, post3];

const sidebar = {
    title: 'O blogu',
    description:
        'Nasz blog to kompendium wiedzy o wyszukiwarce ksiąg wieczystych. Znajdziesz tu aktualności, poradniki i artykuły eksperckie dotyczące korzystania z naszego narzędzia, a także tematyki prawa nieruchomości. Wszystko, co musisz wiedzieć o księgach wieczystych w jednym miejscu!',
    archives: [
        {title: 'sierpień 2023', url: '/blog/archiwum-2023-sierpien'},
        {title: 'lipiec 2023', url: '/blog/npm install react-snap --save-dev\narchiwum-2023-lipiec'},
    ],
    social: [
        {name: 'GitHub', icon: GitHubIcon},
        {name: 'Twitter', icon: TwitterIcon},
        {name: 'Facebook', icon: FacebookIcon},
    ],
};

const theme = createTheme();

export default function Blog() {
    const {postName} = useParams();

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <Helmet>
                <title>{blogTitle}</title>
                <meta name="description" content={blogDescription}/>
                <meta property="og:title" content={blogTitle}/>
                <meta property="og:description" content={blogDescription}/>
                <meta property="og:url" content={window.location.href}/>
            </Helmet>
            <Container maxWidth="lg">
                <main>
                    <MainFeaturedPost post={mainFeaturedPost}/>
                    <Grid container spacing={4}>
                        {featuredPosts.map((post) => (
                            <FeaturedPost key={post.title} post={post}/>
                        ))}
                    </Grid>
                    <Grid container spacing={5} sx={{mt: 3}}>
                        <Main title="Księgi Wieczyste: Wszystko, Co Musisz Wiedzieć" postName={postName} posts={posts}/>
                        <Sidebar
                            title={sidebar.title}
                            description={sidebar.description}
                            archives={sidebar.archives}
                            social={sidebar.social}
                        />
                    </Grid>
                </main>
            </Container>
            <Footer
                title="Wyszukiwarka ksiąg wieczystych"
            />
        </ThemeProvider>
    );
}
