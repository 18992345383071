import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import frontMatter from 'front-matter';
import Markdown from "./Markdown";
import {Helmet} from 'react-helmet';


function Main(props) {
    const {posts, title} = props;
    const [postContent, setPostContent] = useState('');
    const [data, setData] = useState(null);
    const [content, setContent] = useState(null);

    const postName = props.postName;

    const [texts, setTexts] = useState([]);


    useEffect(() => {
        if (postName) {
            import(`./posts/${postName}.md`)
                .then(module => {
                    fetch(module.default)
                        .then(res => res.text())
                        .then(text => setPostContent(text));
                })
                .catch(err => {
                    console.error("Error loading post:", err);
                    setPostContent("Post not found");
                });
        }
    }, [postName]);
    useEffect(() => {
        async function fetchTexts() {
            const textPromises = posts.map(path => fetch(path).then(res => res.text()));
            const texts = await Promise.all(textPromises);
            setTexts(texts);
        }

        fetchTexts();
    }, [posts]);

    useEffect(() => {

        if (postContent) {
            const matter = frontMatter(postContent);

            setData(matter.attributes);
            setContent(matter.body);
        }

    }, [postContent]);


    return (
        <Grid
            item
            xs={12}
            md={8}
            sx={{
                '& .markdown': {
                    py: 3,
                },
            }}
        >
            <Typography variant="h6" gutterBottom>
                {title}
            </Typography>
            <Divider/>
            {!postName && texts.map((post) => (
                <Markdown className="markdown" key={post.substring(0, 40)}>
                    {post}
                </Markdown>
            ))}
            {content && <Helmet>
                <title>{data.title}</title>
                <meta name="description" content={data.description}/>
                <meta name="keywords" content={data.tags.join(', ')}/>
                <meta name="publish_date" content={data.date}/>
            </Helmet>}
            {content && <Markdown className="markdown" key={content.substring(0, 40)}>{content}</Markdown>}
        </Grid>
    );
}

Main.propTypes = {
    posts: PropTypes.arrayOf(PropTypes.string).isRequired,
    title: PropTypes.string.isRequired,
};

export default Main;
