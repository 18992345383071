import {Outlet, useNavigate} from "react-router-dom";

import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";

import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';

import MenuItem from '@mui/material/MenuItem';


function getWindowDimensions() {
    const {innerWidth: width, innerHeight: height} = window;
    return {
        width,
        height
    };
}

function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}

const theme = createTheme({
    palette: {
        primary: {
            main: '#ECDF66'
        }
    },
});

function BasicMenu() {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div style={{height: 60}}>
            <Button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                size="large"
                variant="outlined"
            >
                Menu
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem component="a" href="/blog" onClick={handleClose}>Blog</MenuItem>
                <MenuItem component="a" href="/regulamin" onClick={handleClose}>Regulamin</MenuItem>
                <MenuItem component="a" href="/polityka-prywatnosci" onClick={handleClose}>Polityka
                    prywatności</MenuItem>
                <MenuItem component="a" href="/o-nas" onClick={handleClose}>O nas</MenuItem>
            </Menu>
        </div>
    );
}

const Layout = () => {
    const {height, width} = useWindowDimensions();

    const navigate = useNavigate()

    const goHome = e => {
        navigate('/');
    }


    return (
        <>
            <link href='https://fonts.googleapis.com/css?family=Righteous' rel='stylesheet'/>
            <link href='https://fonts.googleapis.com/css?family=Lato' rel='stylesheet'/>
            <CssBaseline/>
            <AppBar
                position="static"
                color="default"
                elevation={0}
                sx={{borderBottom: (theme) => `1px solid ${theme.palette.divider}`}}
                style={{'background-color': 'white', height: (width > 1000 ? 80 : 60)}}
            >
                <Toolbar>
                    <div style={{width: '50%', 'padding-left': (width - 1200) / 2, 'margin-top': 10}}>
                        <div onClick={goHome} style={{
                            'display': '-webkit-flexbox',
                            'display': '-ms-flexbox',
                            'display': '-webkit-flex',
                            'display': 'flex',
                            '-webkit-flex-align': 'center',
                            '-ms-flex-align': 'center',
                            '-webkit-align-items': 'center',
                            'align-items': 'center',
                            // 'justify-content': 'center'
                            cursor: 'pointer'
                        }}>
                            <font style={{
                                'font-family': 'Righteous',
                                'font-size': width > 1000 ? 32 : 22,
                                color: '#429bcb'
                            }}>E</font>
                            <font style={{
                                'font-family': 'Righteous',
                                'font-size': width > 1000 ? 32 : 22,
                                color: '#44444f'
                            }}>WIECZYSTE.pl</font>
                            {width > 1000 &&
                                <div style={{'margin-left': 20}}>
                                        <span style={{
                                            color: 'black',
                                            'font-size': width > 1000 ? 16 : 12,
                                            'font-family': 'lato',
                                        }}>
                                            Wyszukiwarka ksiąg wieczystych
                                        </span>
                                </div>
                            }
                        </div>
                    </div>
                    <div style={{width: '50%'}}>
                        <div style={{float: 'right', 'padding-right': (width - 1200) / 2, 'padding-top': 20}}>
                            <div style={{
                                'display': '-webkit-flexbox',
                                'display': '-ms-flexbox',
                                'display': '-webkit-flex',
                                'display': 'flex',
                                '-webkit-flex-align': 'center',
                                '-ms-flex-align': 'center',
                                '-webkit-align-items': 'center',
                                'align-items': 'center',
                                // 'justify-content': 'center',
                            }}>
                                <BasicMenu/>
                            </div>
                        </div>
                    </div>
                </Toolbar>
            </AppBar>
            <Outlet/>
        </>
    )
};

export default Layout;
